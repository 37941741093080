import clsx from 'clsx'
import Avatar from 'react-avatar'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { useEffect, useLayoutEffect, useState, useRef } from 'react'
import imageWarning from '../../assets/images/warning.png'

import Chip from '../../UI/Chip'
import Icon from '../../UI/Icon'

import PopUpList from './components/PopupList'
import VisibilityAccess from '../VisibilityAccess'

import { fullLogOut } from '../../shared/helpers'
import { TRoles, TRoutes, TApi, TCompanies, DOCUMENT_TYPES } from '../../shared/const'
import http from '../../shared/http'

import { ReactComponent as PageIcon } from '../../assets/icons/page.svg'
import { ReactComponent as SchoolIcon } from '../../assets/icons/school.svg'
import { ReactComponent as SharesIcon } from '../../assets/icons/shares.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/Logout.svg'
import { ReactComponent as ExpertIcon } from '../../assets/icons/profile.svg'
import { ReactComponent as ReportsIcon } from '../../assets/icons/reports.svg'
import { ReactComponent as MessageIcon } from '../../assets/icons/message.svg'
import { ReactComponent as TwoUserIcon } from '../../assets/icons/two-user.svg'
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg'
import { ReactComponent as InvoicesIcon } from '../../assets/icons/invoices.svg'
import { ReactComponent as StudentsIcon } from '../../assets/icons/students.svg'
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as CompaniesIcon } from '../../assets/icons/companies.svg'
import { ReactComponent as DocumentsIcon } from '../../assets/icons/documents.svg'
import { ReactComponent as RiskIcon } from '../../assets/icons/insuranceIcon.svg'
/* import { ReactComponent as RoundWarningIcon } from '../../assets/icons/round-warning.svg' */

import logoIcon from '../../assets/icons/logo.svg'

import style from './style.module.scss'
import ReactTooltip from 'react-tooltip'
import eventEmitter, { types } from '../../shared/eventEmitter'

function Navbar(props) {
	const { t, i18n } = useTranslation()
	const languageOptions = [
		{ value: 'en', label: 'English' },
		{ value: 'ger', label: 'German' }
	]

	const [activeLanguage, setActiveLanguage] = useState(
		languageOptions.find(({ value }) => value === i18n.language) || languageOptions[0]
	)
	const [isOpenSettings, setIsOpenSettings] = useState(false)
	const [isOpenReports, setIsOpenReports] = useState(false)
	const [avatarSrc, setAvatarSrc] = useState('')
	const [employees, setEmployees] = useState([])
	const [invitedEmployees, setInvitedEmployees] = useState([])
	const location = useLocation()
	const history = useHistory()
	const { pathname } = useLocation()
	const profileElRef = useRef()
	const navElRef = useRef()
	const handleScroll = () => {
		if (!(profileElRef.current && navElRef.current)) return

		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
		const top =
			-navElRef.current.getBoundingClientRect().bottom +
			vh -
			profileElRef.current.getBoundingClientRect().height -
			20

		profileElRef.current.style.top = `${top < 60 ? 60 : top}px`
	}

	const handlePushToMain = () => {
		history.push(TRoutes.MAIN)
	}

	const fetchEmployees = () => {
		http.get(TApi.STUDENT_GET_EMPLOEES).then(({ data }) => setEmployees(data.rows))
		http.get(TApi.STUDENT_GET_INVITED_EMPLOYEES).then(({ data }) => setInvitedEmployees(data))
	}

	useEffect(() => {
		if (
			TRoles.STUDENT === props.role &&
			props.isAuth &&
			pathname !== TRoutes.AUTH_CONFIRM &&
			pathname !== TRoutes.MAIN &&
			props.emailConfirmed
		) {
			fetchEmployees()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}
	}, [
		props.isParentalConfirmation,
		props.rejectedParentalConfirmation,
		props.role,
		props.isAuth,
		props.isSignInMode,
		pathname,
		props.emailConfirmed
	])

	const isStudentsWarning = () =>
		!employees.every(el => {
			return el.Documents[0] && !el.rejectedParentalConfirmation && el.primaryWorkArea
		}) || !!invitedEmployees.length

	useEffect(() => {
		i18n.changeLanguage('ger')
		setActiveLanguage({ value: 'ger', label: 'German' })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useLayoutEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })
		window.addEventListener('resize', handleScroll, { passive: true })
		window.addEventListener('refreshEmployeesNavbar', handleScroll, { passive: true })
		eventEmitter.on(types.refreshEmployeesNavbar, fetchEmployees)

		handleScroll()

		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleScroll)
			eventEmitter.off(types.refreshEmployeesNavbar, fetchEmployees)
		}
	}, [props.isAuth, props.isSignInMode])

	useLayoutEffect(() => {
		ReactTooltip.rebuild()
	})

	useEffect(() => {
		setAvatarSrc(props.avatarHashName ? TApi.PUBLIC_PROFILE_AVATAR(props.avatarHashName) : '')
	}, [props.avatarHashName])

	if (!props.isAuth || location.pathname === TRoutes.AUTH_CONFIRM || location.pathname === TRoutes.MAIN) {
		return (
			<aside className={style.rootUnauthorized}>
				<div className={style.logo} onClick={handlePushToMain}>
					<img src={logoIcon} alt="Logo" />
				</div>
				<div className={style.navProfileContainer}>
					<Select
						isSearchable={false}
						menuPlacement="top"
						classNamePrefix="select"
						className={clsx(style.selectLanguage, style.selectLanguageMini)}
						value={activeLanguage}
						options={languageOptions}
						formatOptionLabel={({ label }) => (
							<span className={style.selectLanguageOption}>
								<Icon name="language" color="#fff" size={{ w: 17, h: 17 }} />
								<span className={clsx(style.selectLanguageLabel, 'option-label')}>{label}</span>
							</span>
						)}
						onChange={option => {
							i18n.changeLanguage(option.value)
							setActiveLanguage(option)
						}}
					/>
					{props.isSignInMode && (
						<NavLink to={TRoutes.MAIN} exact className={style.navExitMini} onClick={fullLogOut}>
							<LogoutIcon className={style.navLogoutIcon} />
						</NavLink>
					)}
				</div>
			</aside>
		)
	}

	const popUpItemsSettings = [
		/* 		{
			name: t('gender'),
			to: '#genders'
		}, */
		{
			name: t('Branches'),
			to: '#branch'
		},
		// {
		// 	name: 'Federal States',
		// 	to: '#schoolTypes'
		// },
		{
			name: t('School Types'),
			to: '#schoolTypes'
		},
		{
			name: t('salutation'),
			to: '#salutations'
		},
		{
			name: t('VAT Rates Revenues'),
			to: '#vatRateRevenues'
		},
		{
			name: t('VAT Rates Expenditure'),
			to: '#vatRateExpenditure'
		},
		{
			name: t('Function & working area'),
			to: '#workAreas'
		},
		{
			name: t('Type of Offers'),
			to: '#typeOfOffers'
		},
		{
			name: t('Shareholder Shares'),
			to: '#shareholderShares'
		},
		// {
		// 	name: 'Reminders',
		// 	to: ''
		// },
		{
			name: t('Share Amounts'),
			to: '#shareAmounts'
		},
		{
			name: t('Junior Insurance'),
			to: '#juniorInsurance'
		},
		{
			name: t('Reports deadline'),
			to: '#reportsDeadline'
		},
		{
			name: t('Invoices overdue time'),
			to: '#invoicesOverdue'
		},
		{
			name: t('Bank Data'),
			to: '#bankData'
		},
		{
			name: t('Company Reminders'),
			to: '#companyReminders'
		},
		{
			name: t('Financial Reporting Reminders'),
			to: '#financialReportingReminders'
		},
		{
			name: t('Invoicing Reminders'),
			to: '#invocingReportingReminders'
		}
		// {
		// 	name: 'PDFs',
		// 	to: ''
		// }
	]

	const popUpItemsReports = [
		{
			name: t('Semiannual'),
			to: `${TRoutes.REPORTS}?type=semiannual`
		},
		{
			name: t('Annual'),
			to: `${TRoutes.REPORTS}?type=annual`
		}
	]

	const adminLinks = [
		{
			name: t('dashboard'),
			Icon: DashboardIcon,
			to: TRoutes.DASHBOARD,
			count: 0
		},
		{
			name: t('companies'),
			Icon: CompaniesIcon,
			to: TRoutes.ADMIN_COMPANIES,
			count: props.user.profile.notifications?.companies
		},
		{
			name: t('schools'),
			Icon: SchoolIcon,
			to: TRoutes.SCHOOLS,
			count: 0
		},
		{
			name: t('users'),
			Icon: StudentsIcon,
			to: TRoutes.USERS,
			count: 0
		},
		{
			name: t('reports'),
			Icon: ReportsIcon,
			to: TRoutes.REPORTS_TABLE,
			count: 0
		},

		{
			name: t('invoices'),
			Icon: InvoicesIcon,
			to: TRoutes.INVOICES,
			count: 0
		},
		{
			name: t('Economic experts'),
			Icon: ExpertIcon,
			to: TRoutes.EXPERTS,
			count: 0
		},
		{
			name: t('documents'),
			Icon: DocumentsIcon,
			to: TRoutes.ADMIN_DOCUMENTS,
			count: 0
		},
		/*{
			name: t('emailNewsletter'),
			Icon: MessageIcon,
			to: TRoutes.EMAILS,
			count: 0,
			className: style.navItemEmail
		},*/
		{
			name: t('Events & News'),
			className: style.navSeparator,
			Icon: PageIcon,
			to: TRoutes.NEWS,
			count: 0
		}
	]

	const linksByRole = {
		[TRoles.STUDENT]: [
			{
				name: t('dashboard'),
				Icon: DashboardIcon,
				to: TRoutes.DASHBOARD,
				count: 0
			},
			{
				name: t('companyProfile'),
				Icon: CompaniesIcon,
				to: TRoutes.COMPANY,
				count: 0
			},
			{
				name: t('riskAnalysis'),
				Icon: RiskIcon,
				to: TRoutes.COMPANY_INSURANCE,
				count: 0
			},
			{
				name: t('employees'),
				Icon: StudentsIcon,
				to: TRoutes.COMPANY_EMPLOYEE,
				count: 0
			},
			{
				name: t('Economic experts'),
				Icon: StudentsIcon,
				to: TRoutes.COMPANY_EXPERTS,
				count: 0
			},
			{
				name: t('documents'),
				Icon: DocumentsIcon,
				to: TRoutes.DOCUMENTS,
				count: 0
			},
			{
				name: t('shares'),
				Icon: SharesIcon,
				to: TRoutes.SHARES,
				count: 0
			},
			{
				name: t('reports'),
				Icon: ReportsIcon,
				to: TRoutes.REPORTS,
				count: 0,
				popUpList: true
			},
			{
				name: t('Messages'),
				Icon: MessageIcon,
				to: TRoutes.NOTIFICATIONS,
				count: props.user.profile.notifications?.messages
			}
		],
		[TRoles.TEACHER]: [
			{
				name: t('dashboard'),
				Icon: DashboardIcon,
				to: TRoutes.DASHBOARD,
				count: 0
			},
			{
				name: t('companies'),
				Icon: CompaniesIcon,
				to: TRoutes.COMPANIES,
				count: 0
			},
			{
				name: t('students'),
				Icon: StudentsIcon,
				to: TRoutes.STUDENTS,
				count: 0
			},
			{
				name: t('documents'),
				Icon: DocumentsIcon,
				to: TRoutes.DOCUMENTS,
				count: 0
			},
			{
				name: t('reports'),
				Icon: ReportsIcon,
				to: TRoutes.REPORTS_TABLE,
				count: 0
			},
			{
				name: t('Messages'),
				Icon: MessageIcon,
				to: TRoutes.NOTIFICATIONS,
				count: props.user.profile.notifications?.messages
			}
		],
		[TRoles.FEDERAL_ADMIN]: [
			...adminLinks,
			{
				name: t('stateAdmins'),
				Icon: TwoUserIcon,
				to: TRoutes.ADMINS,
				count: 0,
				className: style.navItemStateAdmins
			}
		],
		[TRoles.ADMIN]: adminLinks
	}

	return (
		<aside className={style.root}>
			<header className={style.header}>
				<div className={style.logo} onClick={handlePushToMain}>
					<img src={logoIcon} alt="Logo" />
				</div>
				<h1 className={style.title}>Junior Datenbank</h1>
			</header>
			<nav className={style.navigation} ref={navElRef}>
				{linksByRole[props.role].map(item =>
					item?.popUpList && props.reportType === TCompanies.JC && props.reportPeriod?.SEMIANNUAL ? (
						<div className={style.navItemWrapper}>
							<NavLink
								to={location}
								onClick={() => setIsOpenReports(true)}
								className={clsx(
									style.navItem,
									style.navItemReports,
									location.pathname === TRoutes.REPORTS && style.navItemActive
								)}>
								<SettingsIcon className={style.navItemIcon} />
								<span className={style.navItemName}>{t(item.name)}</span>
								<Icon
									className={style.navItemIconSettings}
									name="arrow"
									size={{ w: 11, h: 9 }}
									color="#ffffff"
								/>
							</NavLink>
							<PopUpList
								popUpItems={popUpItemsReports}
								isOpen={isOpenReports}
								onClose={() => setIsOpenReports(false)}
							/>
						</div>
					) : (
						<NavLink
							key={item.name}
							to={item.to}
							className={clsx(style.navItem, item.className)}
							activeClassName={style.navItemActive}>
							<item.Icon className={style.navItemIcon} />
							<span className={style.navItemName}>{item.name}</span>
							{!!item.count && <Chip className={style.navItemCount}>+{item.count}</Chip>}
							{item.name === t('employees') && isStudentsWarning() && (
								<VisibilityAccess access={[TRoles.STUDENT]}>
									<img
										src={imageWarning}
										className={style.warningParentalConfirmation}
										data-tip={t(
											'This icon shows incomplete steps, such as missing parental confirmation, unassigned working area, or pending employee invitation.'
										)}
										alt=""
									/>
								</VisibilityAccess>
							)}
						</NavLink>
					)
				)}
				<VisibilityAccess access={[TRoles.FEDERAL_ADMIN]}>
					<div className={style.navItemWrapper}>
						<NavLink
							to={TRoutes.SETTINGS}
							exact
							className={style.navItem}
							activeClassName={style.navItemActive}
							onClick={() => setIsOpenSettings(true)}>
							<SettingsIcon className={style.navItemIcon} />
							<span className={style.navItemName}>{t('settings')}</span>
							<Icon
								className={style.navItemIconSettings}
								name="arrow"
								size={{ w: 11, h: 9 }}
								color="#ffffff"
							/>
						</NavLink>
						<PopUpList
							popUpItems={popUpItemsSettings}
							isOpen={isOpenSettings}
							onClose={() => setIsOpenSettings(false)}
						/>
					</div>
				</VisibilityAccess>
			</nav>
			<div className={style.navProfileContainer} ref={profileElRef}>
				<NavLink to={TRoutes.PROFILE} exact className={style.navProfile} activeClassName={style.navItemActive}>
					<Avatar src={avatarSrc} round name={props.fullName} size={36} className={style.preview} />
					<span className={style.navProfileName}> {t(props.fullName)}</span>
					{(!props.isParentalConfirmation || props.rejectedParentalConfirmation) && (
						<VisibilityAccess access={[TRoles.STUDENT]}>
							{/* 							<RoundWarningIcon
								data-tip={
									props.rejectedParentalConfirmation
										? t('Parental confirmation was rejected, upload new please !')
										: t('Need to upload parental confirmation')
								}
								className={style.warningParentalConfirmation}
							/> */}
							<img
								src={imageWarning}
								className={style.warningParentalConfirmation}
								data-tip={
									props.rejectedParentalConfirmation
										? t('Parental confirmation was rejected, upload new please !')
										: t('Need to upload parental confirmation')
								}
								alt=""
							/>
						</VisibilityAccess>
					)}
				</NavLink>

				<Select
					isSearchable={false}
					menuPlacement="top"
					classNamePrefix="select"
					className={style.selectLanguage}
					value={activeLanguage}
					options={languageOptions}
					formatOptionLabel={({ label }) => (
						<span className={style.selectLanguageOption}>
							<Icon name="language" color="#fff" size={{ w: 17, h: 17 }} />
							<span className={clsx(style.selectLanguageLabel, 'option-label')}>{label}</span>
						</span>
					)}
					onChange={option => {
						i18n.changeLanguage(option.value)
						setActiveLanguage(option)
					}}
				/>

				{!props.isTeacherStudentView && (
					<NavLink
						to={TRoutes.MAIN}
						exact
						className={style.navExit}
						activeClassName={style.navItemActive}
						onClick={fullLogOut}>
						<LogoutIcon className={style.navLogoutIcon} />
						<span
							className={
								[TRoles.ADMIN, TRoles.FEDERAL_ADMIN].includes(props.role)
									? style.navItemName
									: style.navLogoutName
							}>
							{t('exit')}
						</span>
					</NavLink>
				)}

				<div className={style.policyLinkLogIn}>
					<a className={style.impintLink} href="https://www.junior.cc/impressum" download>
						Impressum
					</a>
					<a className={style.impintLink} href="/JAA-Datenschutzerklärung.pdf" download>
						Datenschutzerklärung
					</a>
					<a className={style.poweredLink} href="https://davincilab.at/">
						Powered by DaVinciLab
					</a>
				</div>
			</div>
		</aside>
	)
}

Navbar.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	isSignInMode: PropTypes.bool.isRequired,
	fullName: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	isParentalConfirmation: PropTypes.bool.isRequired,
	reportType: PropTypes.string.isRequired,
	reportPeriod: PropTypes.object.isRequired,
	isTeacherStudentView: PropTypes.bool.isRequired
}
Navbar.defaultProps = {
	rejectedParentalConfirmation: false,
	isParentalConfirmation: true
}

const mapStateToProps = state => ({
	isAuth: state.user.isAuth,
	isSignInMode: state.user.isSignInMode,
	role: state.user.role,
	fullName: `${state.user.profile.firstName} ${state.user.profile.lastName}`,
	user: state.user,
	avatarHashName: state.user.profile.avatarHashName,
	reportType: state.user.profile.EmploymentCompany?.companyType,
	isParentalConfirmation: !!state.user.profile.Documents?.find(
		({ type }) => type === DOCUMENT_TYPES.PARENTAL_CONFIRMATION
	),
	reportPeriod: state.user.profile.reportPeriod,
	rejectedParentalConfirmation: state.user.profile.rejectedParentalConfirmation,
	emailConfirmed: state.user.profile.emailConfirmed,
	isTeacherStudentView: state.user.isTeacherStudentView
})

export default connect(mapStateToProps)(Navbar)
