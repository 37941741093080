import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import MiniHeader from '../../../../UI/MiniHeader'

import Branches from './components/Branch'
import BankData from './components/BankData'
import ShareAmounts from './components/ShareAmounts'
import MultipleComponent from './components/MultipleComponent'
import JuniorInsurance from './components/JuniorInsurance'
import ShareholderShares from './components/ShareholderShares'
import ReportsDeadLine from './components/ReportsDeadLine'
import InvoicesOverdue from './components/InvoicesOverdue'
import ClearDB from './components/clearDB'
import VisibilityAccess from '../../../VisibilityAccess'
import CompanyPresentationType from './components/CompanyPresentationType'

import InvocingReportingReminders from './components/InvocingReportingReminders'
import CompanyReminders from './components/CompanyReminders'
import FinancialReportingReminders from './components/FinancialReportingReminders'
import Rates from './components/Rates'

import { updateOptions } from '../../../../redux/ducks/options'

import http from '../../../../shared/http'
import { TApi, TRoles } from '../../../../shared/const'

import styles from './style.module.scss'
import Salutations from './components/Salutations'
import WorkAreas from './components/WorkAreas'
import TypeOfOffers from './components/TypeOfOffers'

function Settings(props) {
	const permissions = props.permissions
	const { t } = useTranslation()
	const fetchOptions = () => {
		http.get(TApi.COMMON_GET_OPTIONS).then(({ data }) => {
			props.updateOptions(data)
		})
	}

	const updateOptions = async form => {
		return http.put(TApi.ADMIN_OPTIONS, form).then(({ data }) => {
			props.updateOptions(data)
		})
	}

	useEffect(() => {
		fetchOptions()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { hash } = useLocation()

	useEffect(() => {
		if (hash) {
			const id = hash.replace('#', '')
			const element = document.getElementById(id)

			if (element) {
				element.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}, [hash])

	const data = [
		/* 	{ name: 'genders', title: t('gender') }, */
		// { name: 'branch', title: t('branch') },
		{ name: 'schoolTypes', title: t('School Types') }
		// { name: 'salutations', title: t('salutation') },
		// { name: 'vatRateRevenues', title: t('VAT Rates Revenues'), number: true },
		// { name: 'vatRateExpenditure', title: t('VAT Rates Expenditure'), number: true },
		// { name: 'workAreas', title: t('Work Areas') },
		// { name: 'typeOfOffers', title: t('Type of Offers') }
	]
	return (
		<div className={styles.root}>
			<MiniHeader title={t('settings')} bg="#f8faff" />
			<div className="container">
				<Branches form={props.options} updateOptions={updateOptions} />
				<Salutations form={props.options} updateOptions={updateOptions} />
				<WorkAreas form={props.options} updateOptions={updateOptions} />
				<TypeOfOffers form={props.options} updateOptions={updateOptions} />
				{data?.map(v => (
					<MultipleComponent
						name={v.name}
						title={v.title}
						number={v?.number}
						form={props.options}
						updateOptions={updateOptions}
					/>
				))}
				<Rates
					name="vatRateRevenues"
					title={t('VAT Rates Revenues')}
					form={props.options}
					updateOptions={updateOptions}
				/>
				<Rates
					name="vatRateExpenditure"
					title={t('VAT Rates Expenditure')}
					form={props.options}
					updateOptions={updateOptions}
				/>
				<ShareholderShares form={props.options} updateOptions={updateOptions} />
				<ShareAmounts form={props.options} updateOptions={updateOptions} />
				<JuniorInsurance form={props.options} updateOptions={updateOptions} />
				<ReportsDeadLine form={props.options} updateOptions={updateOptions} />
				<FinancialReportingReminders form={props.options} updateOptions={updateOptions} />
				<BankData form={props.options} updateOptions={updateOptions} />
				<InvoicesOverdue form={props.options} updateOptions={updateOptions} />
				<InvocingReportingReminders form={props.options} updateOptions={updateOptions} />
				<CompanyReminders form={props.options} updateOptions={updateOptions} />
				<VisibilityAccess access={[TRoles.FEDERAL_ADMIN]}>
					<CompanyPresentationType form={props.options} updateOptions={updateOptions} />
					{permissions?.manageDatabaseReset && (
						<ClearDB updateOptions={updateOptions} options={props.options} />
					)}
				</VisibilityAccess>
			</div>
		</div>
	)
}
Settings.propTypes = {
	options: PropTypes.object.isRequired,

	updateOptions: PropTypes.func.isRequired,
	permissions: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
	options: state.options,
	permissions: state.user.profile.permissions
})
const mapDispatchToProps = dispatch => ({
	updateOptions: data => dispatch(updateOptions(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Settings)
