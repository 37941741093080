const en = {
	translations: {
		dashboard: 'Dashboard',
		companyProfile: 'Company Profile',
		riskAnalysis: 'Risk Analysis',
		employees: 'Employees',
		documents: 'Documents',
		shares: 'Shares',
		reports: 'Reports',
		Reports: 'Reports',
		experts: 'Experts',
		companies: 'Companies',
		students: 'Students',
		users: 'Users',
		invoices: 'Invoices',
		income: 'Income',
		emailNewsletter: 'Email & Newsletter',
		newsPages: 'News Pages',
		stateAdmins: 'Administration',
		settings: 'Settings',
		schools: 'Schools',
		profile: 'Profile',
		exit: 'Exit',
		'Economic experts': 'Economic experts',
		uploadBP: 'Upload business Plan',
		companyEmployees: 'Company Employees',
		news: 'News',
		event: 'Event',
		genders: 'Genders',
		'School Types': 'School Types',
		'VAT Rates Revenues': 'VAT Rates Revenues',
		'VAT Rates Expenditure': 'VAT Rates Expenditure',
		'Work Areas': 'Work Areas',
		'Type of Offers': 'Type of Offers',
		'Shareholder Shares': 'Shareholder Shares',
		'Max. Share Amounts': 'Max. Share Amounts',
		'Junior Insurance': 'Junior Insurance',
		'Reports deadline': 'Reports deadline',
		'Invoices overdue time': 'Invoices overdue time',
		'Bank Data': 'Bank Data',
		'No news yet': 'No news yet',
		'No shares yet': 'No shares yet',
		'No reports yet': 'No reports yet',
		'Supervision Teacher': 'Supervision Teacher',
		Report: 'Report',
		Registration: 'Registration',
		'BUSINESS ACTIVITIES': 'BUSINESS ACTIVITIES',
		'Basic Info': 'Basic Info',
		'Company name': 'Company name',
		'The company name as well as the company type, if any, can only be changed via the country administrator.':
			'The company name as well as the company type, if any, can only be changed via the country administrator.',
		Note: 'Note',
		'Company type': 'Company type',
		state: 'State',
		save: 'Save',
		contacts: 'Contacts',
		'Company email': 'Company email',
		CompanyEmailDescription:
			'The company e-mail address mitgh be used for contact with external stakeholders and published for e.g. on the company`s website, incoices, etc. If your company does not yet have an e-mail address, you can creat one for your company free of charge at providers such gmx.at, gmail.com or icloud.com ',
		phone: 'Phone',
		phoneDesc:
			'The company phone number mitgh be used for contact with external stakeholders and published for e.g. on the company`s website, invoices, etc.',
		school: 'School',
		schoolDesc:
			'The JUNIOR Company will be registered at your school location. Please choose an already registered school or enter a new school location.',
		teacherDesc:
			'Please select an already registered JUNIOR teacher. If your teacher is not yet registered with us: register a new teacher here!',
		'Business Activity': 'Business Activity',
		branch: 'Branch',
		description: 'Description',
		descriptionNote:
			'The JUNIOR Company will be registered at your school location. Please choose an already registered school or enter a new school location.',
		typeOffers: 'Type of offers',
		typeOffersNote:
			'Please select an already registered JUNIOR teacher. If your teacher is not yet registered with us: register a new teacher here!',
		descriptionPlaceholder: 'Type short company description',
		'Business Idea': 'Business Idea',
		'Your Business Idea': 'Your Business Idea',
		'We are the...': 'We are the...',
		'Symbols Left': 'Symbols Left',
		Symbols: 'Symbols',
		'Detailed description of business activity': 'Detailed description of business activity',
		'Our...': 'Our...',
		'Select Branch': 'Select Branch',
		'Company Employee': 'Company Employee',
		'Go back': 'Go back',
		'Add Expert': 'Add Expert',
		'Company Documents': 'Company Documents',
		EMPLOYEE: 'EMPLOYEE',
		'Company Shares': 'Company Shares',
		Shareholder: 'Shareholder',
		shareholdersDesc:
			'All capital investors must be registered with their first and last name and a valid address. As soon as this data is complete, the investor will receive a personal certificate of participation in your Junior Company by e-mail. Please make sure that all data is correct at the first entry, later',
		Important: 'Important',
		salutation: 'Salutation',
		'Title Prefixed': 'Title prefixed',
		'First Name': 'First name',
		'Last Name': 'Last name',
		'Title Suffixed': 'Title suffixed',
		gender: 'Gender',
		'Select the amount': 'Select the amount',
		'Company Share': 'Company Share',
		'Warrant Upload': 'Warrant Upload',
		'Please upload the warrant!': 'Please upload the warrant!',
		'Shareholder Phone': 'Shareholder Phone',
		'House number': 'House number',
		'Zip Code': 'Zip Code',
		City: 'City',
		'Type First Name': 'Type First Name',
		'Enter Title': 'Enter Title',
		'Type Last Name': 'Type Last Name',
		'Select Gender': 'Select Gender',
		SHAREHOLDER: 'SHAREHOLDER',
		'Market and Risk Analysis': 'Market and Risk Analysis',
		'Market Description': 'Market Description',
		'To market description': 'To market description',
		'Target group(s)': 'Target group(s)',
		'Product usage': 'Product usage',
		'Add usage...': 'Add usage...',
		'To product benefit': 'To product benefit',
		'Describe your target group(s) as precisely as possible with their specific characteristics, such as age group, life situation, interests, whereabouts, etc.':
			'Describe your target group(s) as precisely as possible with their specific characteristics, such as age group, life situation, interests, whereabouts, etc.',
		'What added value does your offer bring to the target group, what needs does it serve? For what benefit does the customer pay the purchase price?':
			'What added value does your offer bring to the target group, what needs does it serve? For what benefit does the customer pay the purchase price?',
		'Customer contact, sales strategy': 'Customer contact, sales strategy',
		'Where is/are your target group(s) to be found? How do you promote your offer to the respective target group? Which communication channels do you use?':
			'Where is/are your target group(s) to be found? How do you promote your offer to the respective target group? Which communication channels do you use?',
		'Add contact & Strategy': 'Add contact & Strategy',
		'Sales forecast': 'Sales forecast',
		'Enter your expected sales figures for your up to 5 most important offers and their respective prices. This will give you a forecast of your sales for the entire project year.':
			'Enter your expected sales figures for your up to 5 most important offers and their respective prices. This will give you a forecast of your sales for the entire project year.',
		'Product / Service': 'Product / Service',
		'Product name': 'Product name',
		'Price (€) / unit': 'Price (€) / unit',
		'Expected sales (number)': 'Expected sales (number)',
		'Expected turnover (€)': 'Expected turnover (€)',
		'Add new forecast': 'Add new forecast',
		'The total amount of estimated annual sales is €.': 'The total amount of estimated annual sales is €.',
		'Risk and strategy': 'Risk and strategy',
		'Enter here possible risks affecting your company and describe a strategy for dealing with the risk in each case. Risks can arise, for example, in connection with your production, with the target group, with suppliers or with the competition.':
			'Enter here possible risks affecting your company and describe a strategy for dealing with the risk in each case. Risks can arise, for example, in connection with your production, with the target group, with suppliers or with the competition.',
		Risk: 'Risk',
		'Avoidance strategy': 'Avoidance strategy',
		'Add avoidance strategy': 'Add avoidance strategy',
		'Add new Risk': 'Add new Risk',
		'Date of Birth': 'Date of Birth',
		'Letter has been sent to confirm email changes.': 'Letter has been sent to confirm email changes.',
		'Your email': 'Your email',
		'Change email': 'Change email',
		'Profile has been updated successfully.': 'Profile has been updated successfully.',
		'Avatar photo': 'Avatar photo',
		'Upload profile photo': 'Upload profile photo',
		'Parental Confirmation': 'Parental Confirmation',
		'click for open file': 'click for open file',
		ParentalDesc:
			'	The e-mail address of the company is used for contact with the JUNIOR Company and functions as a login to the database. If your company does not yet have an e-mail address, you can create one for your company free of charge at providers such as gmx.at, gmail.com or icloud.com.',
		'Residential address': 'Residential address',
		'Your contact data will only be used for our communication with you and will not be passed on to third parties!':
			'Your contact data will only be used for our communication with you and will not be passed on to third parties!',
		'Main function': 'Main function',
		'Your main work area': 'Your main work area',
		'2nd work area': '2nd work area',
		'3rd work area': '3rd work area',
		'Passsword has been changed successfuly.': 'Passsword has been changed successfuly.',
		'Change PW section': 'Change PW section',
		'Old password': 'Old password',
		'New password': 'New password',
		'Repeat new password': 'Repeat new password',
		'Change Password': 'Change Password',
		'My Profile': 'My Profile',
		'The expert was': 'The expert was',
		updated: 'updated',
		created: 'created',
		successfully: 'successfully',
		'Update economic expert': 'Update economic expert',
		'Add economic expert': 'Add economic expert',
		Company: 'Company',
		'Function, position': 'Function, position',
		update: 'Update',
		Cancel: 'Cancel',
		'Add Employee': 'Add Employee',
		'Invitation was sent successfully': 'Invitation was sent successfully',
		'CEO Updated': 'CEO Updated',
		'Send email to all users': 'Send email to all users',
		You: 'You',
		Employee: 'Employee',
		Director: 'Director',
		'Make CEO': 'Make CEO',
		'Invite new employee by Email': 'Invite new employee by Email',
		'Employee Email': 'Employee Email',
		'Teacher added successfully': 'Teacher added successfully',
		'Invite deleted successfully': 'Invite deleted successfully',
		'Lehrer erfolgreich aus Ihrem Unternehmen gelöscht': 'Lehrer erfolgreich aus Ihrem Unternehmen gelöscht',
		'Invite Teacher': 'Invite Teacher',
		Student: 'Student',
		Teacher: 'Teacher',
		Pending: 'Pending',
		Remove: 'Remove',
		'Select teacher': 'Select teacher',
		Verified: 'Verified',
		Send: 'Send',
		'Teacher Email': 'Teacher Email',
		'Or invite new teacher by Email': 'Or invite new teacher by Email',
		'You can create first report after Company activation': 'You can create first report after Company activation',
		'Risk Analysis Form': 'Risk Analysis Form',
		'Go To Reports': 'Go To Reports',
		'Go To Shares': 'Go To Shares',
		'Go To News': 'Go To News',
		'Go To Companies': 'Go To Companies',
		'Sign In': 'Sign In',
		'Do not have account yet?': 'Do not have account yet?',
		SignUp: {
			noun: 'Sign Up',
			verb: 'Sign Up'
		},
		Password: 'Password',
		'Your password': 'Your password',
		'Forgot password?': 'Forgot password?',
		'Recover Pass': 'Recover Password',
		'A letter with a link to reset the password is sent to the e-mail':
			'A letter with a link to reset the password is sent to the e-mail',
		'Back to': 'Back to',
		page: 'page',
		Recover: 'Recover',
		'Password changed successfully !': 'Password changed successfully !',
		'Successfully updated': 'Successfully updated',
		'Password Recovery': 'Password Recovery',
		'Set password for your new account': 'Set password for your new account',
		'Create a new password': 'Create a new password',
		'Expert Activation': 'Expert Activation',
		'Account confirmation is in progress. Loading...': 'Account confirmation is in progress. Loading...',
		'account successfully activated': 'account successfully activated',
		Activated: 'Activated',
		'Token is not founded': 'Token is not founded',
		'Account confirmation': 'Account confirmation',
		Dashboard: 'Dashboard',
		'Go to': 'Go to',
		'Registration was successful. Please sign in': 'Registration was successful. Please sign in',
		'Registration was successful. Please confirm email': 'Registration was successful. Please confirm email',
		'as student account': 'as student account',
		'Have account?': 'Have account?',
		Role: 'Role',
		'Repeat password': 'Repeat password',
		'I agree that the above data will be collected and stored by Junior Achievement Austria for the duration of this school year for the purpose of fulfilling and processing the Junior Project. These data will not be passed on to third parties by Junior Achievement Austria and will be removed from the database after the end of the school year.':
			'I agree that the above data will be collected and stored by Junior Achievement Austria for the duration of this school year for the purpose of fulfilling and processing the Junior Project. These data will not be passed on to third parties by Junior Achievement Austria and will be removed from the database after the end of the school year.',
		'Step 2': 'Step 2',
		'Provide your personal details': 'Provide your personal details',
		'Select salutation': 'Select salutation',
		'Select gender': 'Select gender',
		Back: 'Back',
		Continue: 'Continue',
		Submit: 'Submit',
		'Create Company': 'Create Company',
		'Choose company Type': 'Choose company Type',
		'Classical Junior Company for 15-19 year old students. Duration 1 school year (2 semesters)':
			'Classical Junior Company for 15-19 year old students. Duration: 1 school year (2 semesters)',
		'Simplified Junior Company for 7th-9th grade. Duration 1 semester. Simplified Accounting.':
			'Simplified Junior Company for 7th-9th grade. Duration: 1 semester. Simplified Accounting.',
		'Shortened Junior Company for 9th-13th grade students. Can be done in 1 semester.':
			'Shortened Junior Company for 9th-13th grade students. Can be done in 1 semester.',
		Create: 'Create',
		'Provide us company data': 'Provide us company data',
		'Company state': 'Company state',
		'Type Short Company Description': 'Type Short Company Description',
		'Hello, welcome back': 'Hello, welcome back',
		'Edit My Profile': 'Edit My Profile',
		'Supervision MaleTeacher in': 'Supervision Teacher in',
		'Supervision FemaleTeacher in': 'Supervision Teacher in',
		'Last Assigned Companies': 'Last Assigned Companies',
		'Last Assigned Students': 'Last Assigned Students',
		'Full list': 'Full list',
		'No companies yet': 'No companies yet',
		'All Students In School': 'All Students In School',
		'No students yet': 'No students yet',
		'ASSIGNED COMPANIES': 'ASSIGNED COMPANIES',
		'Search companies...': 'Search companies...',
		'Assigned Students': 'Assigned Students',
		'Search for a student': 'Search for a student',
		'Registration Date': 'Registration Date',
		Certificate: 'Certificate',
		Insurance: 'Insurance',
		'Business Plan': 'Business Plan',
		'Search for documents...': 'Search for documents...',
		Type: 'Type',
		'Contacts updated': 'Contacts updated',
		'Change contacts': 'Change contacts',
		Select: 'Select',
		'Change School': 'Change School',
		'General Info': 'General Info',
		Customise: 'Customise',
		'Quick Lists': 'Quick Lists',
		'Last Companies': 'Last Companies',
		'Last Students': 'Last Students',
		'Last Teachers': 'Last Teachers',
		'Available widgets': 'Available Widgets',
		'Max number of widgets is 8': 'Max number of widgets is 8',
		'Widgets updated': 'Widgets updated',
		Members: 'Members',
		member: 'member',
		SCHOOL: 'SCHOOL',
		BaLance: 'Balance',
		Registered: 'Registered',
		'Download list as': 'Download list as:',
		'Create School': 'Create School',
		'Update School': 'Update School',
		'Find a school': 'Find a school',
		Result: 'Result',
		from: 'from',
		'Find a user': 'Find a user',
		AMOUNT: 'AMOUNT',
		'Search a invoice': 'Search a invoice',
		'Total Invoice Amount': 'Total Invoice Amount',
		'Paid Invoices, %': 'Paid Invoices, %',
		'Paid Invoices': 'Paid Invoices',
		'Overdue Invoices': 'Overdue Invoices',
		'TEMPLATE NAME': 'TEMPLATE NAME',
		'MODIFIED DATE': 'MODIFIED DATE',
		'Email Templates': 'Email Templates',
		Templates: 'Templates',
		'Create Template': 'Create Template',
		'New email template': 'New email template',
		'Template name': 'Template name',
		'Email body': 'Email body',
		'Events & News': 'Events & News',
		'Create event': 'Create event',
		Edit: 'Edit',
		'Target groups': 'Target groups',
		Place: 'Place',
		All: 'All',
		'Start date': 'Start date',
		'End date': 'End date',
		'External Link': 'External Link',
		'Add event': 'Add event',
		Title: 'Title',
		'Select state or states': 'Select state or states',
		'User roles': 'User roles',
		'Select role or roles': 'Select role or roles',
		'Select type or types': 'Select type or types',
		'Organization name': 'Organization name',
		'Administrator email': 'Administrator email',
		'Add Administrator': 'Add Administrator',
		Administrators: 'Administrators',
		'Invitation Pending': 'Invitation Pending',
		'The expert was deleted successfully': 'The expert was deleted successfully',
		Accepted: 'Accepted',
		'Companies Pending, %': 'Companies Pending, %',
		Resolve: 'Resolve',
		'Show Invoices': 'Show Invoices',
		'Customize Multi Widget': 'Customize Multi Widget',
		'First Slot': 'First Slot',
		'Second Slot': 'Second Slot',
		'Third Slot': 'Third Slot',
		'Fourth Slot': 'Fourth Slot',
		'Customize Companies Widget': 'Customize Companies Widget',
		New: 'New',
		Overdue: 'Overdue',
		Unpaid: 'Unpaid',
		teachers: 'Teachers',
		Turnover: 'Turnover',
		'Confirm Company Profile': 'Confirm Company Profile',
		Documentation: 'Documentation',
		'Business activity empty': 'Business activity empty',
		'Type of offers empty': 'Type of offers empty',
		'Business idea empty': 'Business idea empty',
		'Company Description': 'Company Description',
		'Company description empty': 'Company description empty',
		'Add Admin Comment': 'Add Admin Comment',
		'Your Comment about Company (Only administrators will see this comment)':
			'Your Comment about Company (Only administrators will see this comment)',
		'Type comment about company': 'Type comment about company',
		'Activate Company': 'Activate Company',
		'Annual financial statements': 'Annual financial statements',
		Expenditures: 'Expenditures',
		'Profit/Loss': 'Profit/Loss',
		'To successfully complete the Junior Basic Company the following final accounting must be done.':
			'To successfully complete the Junior Basic Company the following final accounting must be done.',
		Revenue: 'Revenue',
		'Total gross revenue': 'Total gross revenue',
		'All income from your sales WITHOUT income from the sale of Junior Basic Company shares.':
			'All income from your sales WITHOUT income from the sale of Junior Basic Company shares.',
		Expenses: 'Expenses',
		'Total salary expenses': 'Total salary expenses',
		'All expenditures for wages of employees of your Junior Basic Company.':
			'All expenditures for wages of employees of your Junior Basic Company.',
		'Material expenses': 'Material expenses',
		'All expenses for the materials you needed to make your products.':
			'All expenses for the materials you needed to make your products.',
		'Other expenses': 'Other expenses',
		'Other expenses such as transportation or travel expenses, company Christmas party, etc.':
			'Other expenses such as transportation or travel expenses, company Christmas party, etc.',
		'Total gross expenses': 'Total gross expenses',
		Validate: 'Validate',
		Next: 'Next',
		'Profit/Loss was submitted': 'Profit/Loss was submitted',
		'Your income minus expenses result in the profit or loss. Important a loss is written as a minus number!':
			'Your income minus expenses result in the profit or loss. Important: a loss is written as a minus number!',
		'insurance contribution': 'Insurance contribution',
		'Insurance premium for all activities of your Junior Company.':
			'Insurance premium for all activities of your Junior Company.',
		'Net profit/loss': 'Net profit/loss',
		'Profit/loss minus insurance contribution gives the net profit/loss.':
			'Profit/loss minus insurance contribution gives the net profit/loss.',
		'Date of submission (cannot be changed)': 'Date of submission (cannot be changed)',
		Previous: 'Previous',
		'Calculation of profit loss before taxes': 'Calculation of profit loss before taxes',
		'Calculation of corporate income tax': 'Calculation of corporate income tax',
		'Calculation of net profit/loss': 'Calculation of net profit/loss',
		'Calculation of levies to Junior': 'Calculation of levies to Junior',
		'Calculation of profit/loss before taxes was updated': 'Calculation of profit/loss before taxes was updated',
		'To successfully complete the Junior Compact Company the following final financial statement must be made.':
			'To successfully complete the Junior Compact Company the following final financial statement must be made.',
		'Total Income': 'Total Income',
		'Total revenue.': 'Total revenue',
		'All income from your sales and other income WITHOUT income from the sale of Junior Compact Company shares!':
			'All income from your sales and other income WITHOUT income from the sale of Junior Compact Company shares!',
		'Salary expenses': 'Salary expenses',
		'All expenses for wages of employees of your Junior Compact Company ("Entrepreneur`s Wages").':
			'All expenses for wages of employees of your Junior Compact Company ("Entrepreneur`s Wages").',
		'All expenses for materials you needed to produce your products, including sales taxes.':
			'All expenses for materials you needed to produce your products, including sales taxes.',
		'Other expenses such as transportation or travel expenses, company Christmas party, ....':
			'Other expenses such as transportation or travel expenses, company Christmas party, ....',
		'Sum of expenses': 'Sum of expenses',
		'Calculation of profit/loss before taxes': 'Calculation of profit/loss before taxes',
		'Profit/Loss (before tax)': 'Profit/Loss (before tax)',
		'Preliminary profit/loss before deduction of taxes.': 'Preliminary profit/loss before deduction of taxes.',
		'- 13% Salary-related social security contributions': '- 13% Salary-related social security contributions',
		'Depending on the amount of salaries or entrepreneur wages paid, 13% social contributions must be paid.':
			'Depending on the amount of salaries or entrepreneur wages paid, 13% social contributions must be paid.',
		'Salary-related social security contributions': 'Salary-related social security contributions',
		'Insurance contribution': 'Insurance contribution',
		'Insurance contribution for all activities of your Junior Company.':
			'Insurance contribution for all activities of your Junior Company.',
		'Profit/loss (before corporate income tax)': 'Profit/loss (before corporate income tax)',
		'Preliminary profit/loss before deduction of corporate income tax, which is due only in case of profit.':
			'Preliminary profit/loss before deduction of corporate income tax, which is due only in case of profit.',
		'Calculation of corporate income tax was updated': 'Calculation of corporate income tax was updated',
		'25% corporate income tax': '25% corporate income tax',
		'Corporate income tax': 'Corporate income tax',
		'Corporate income is taxed at a fixed rate of 25%.': 'Corporate income is taxed at a fixed rate of 25%.',
		'Founder`s bonus (20% of corporate income tax)': 'Founder`s bonus (20% of corporate income tax)',
		'This represents a tax relief of 20% of the corporate income tax exclusively for junior companies.':
			'This represents a tax relief of 20% of the corporate income tax exclusively for junior companies.',
		'Reduced corporate income tax': 'Reduced corporate income tax',
		'The calculated amount represents the deductible corporate income tax.':
			'The calculated amount represents the deductible corporate income tax.',
		'Calculation of net profit/loss was updated': 'Calculation of net profit/loss was updated',
		'Calculation of net profit/loss (to be distributed)': 'Calculation of net profit/loss (to be distributed)',
		'The reduced corporate income tax is to be deducted from the provisional profit.':
			'The reduced corporate income tax is to be deducted from the provisional profit.',
		'Net profit / Net loss': 'Net profit / Net loss',
		'This amount represents your profit/loss after deduction of taxes.':
			'This amount represents your profit/loss after deduction of taxes.',
		'Calculation of levies to Junior was submitted': 'Calculation of levies to Junior was submitted',
		'Levies to be transferred to Junior Austria': 'Levies to be transferred to Junior Austria',
		'According to this schedule, levies are to be remitted to the account indicated in the invoice.':
			'According to this schedule, levies are to be remitted to the account indicated in the invoice.',
		'+ 13% salary-related social security contributions': '+ 13% salary-related social security contributions',
		'= Taxes to be transferred to Junior Austria': '= Taxes to be transferred to Junior Austria',
		'Revenues and sales': 'Revenues and sales',
		'Expenses and input tax deduction': 'Expenses and input tax deduction',
		'Salary-related taxes (employee share)': 'Salary-related taxes (employee share)',
		'Advance return for sales tax': 'Advance return for sales tax',
		'Calculation half-year result': 'Calculation half-year result',
		'Financial report first semester': 'Financial report first semester',
		'Revenues was deleted': 'Revenues was deleted',
		'Revenues and sales was updated': 'Revenues and sales was updated',
		'Deadline by DD.MM.YYYY': 'Deadline by DD.MM.YYYY',
		'Income from the sale of company shares does not count towards your sales!':
			'Income from the sale of company shares does not count towards your sales!',
		'Here you can create new groups of turnovers, with different VAT rates.':
			'Here you can create new groups of turnovers, with different VAT rates.',
		'Turnovers in € incl. VAT': 'Turnovers in € incl. VAT',
		'VAT rate': 'VAT rate',
		'Turnover total': 'Turnover total',
		'of which': 'of which',
		'% VAT in €': '% VAT in €',
		'Create new entry': 'Create new entry',
		'Junior tax-exempt revenues': 'Junior tax-exempt revenues',
		'These revenues do not count towards the calculation of junior corporate tax and do not include VAT - e.g. sponsorship.':
			'These revenues do not count towards the calculation of junior corporate tax and do not include VAT - e.g. sponsorship.',
		'Total in €': 'Total in €',
		'For VAT-exempt revenue from sponsorship, the company must provide a consideration in the form of advertising or public relations. Describe here what consideration you will provide for each sponsorship received.':
			'For VAT-exempt revenue from sponsorship, the company must provide a consideration in the form of advertising or public relations. Describe here what consideration you will provide for each sponsorship received.',
		'Describe returns...': 'Describe returns...',
		'Material expenses were deleted': 'Material expenses were deleted',
		'Other expenses were deleted': 'Other expenses were deleted',
		'Expenses and input tax deduction was updated': 'Expenses and input tax deduction was updated',
		'Enter the total of your material and other expenses and the input tax (sales tax) included in them. The input tax is offset against the sales tax collected on your sales and reduces the tax payment.':
			'Enter the total of your material and other expenses and the input tax (sales tax) included in them. The input tax is offset against the sales tax collected on your sales and reduces the tax payment.',
		'Sales tax rate': 'Sales tax rate',
		'Material expenses in € incl. <%> VAT.': 'Material expenses in € incl. % VAT.',
		'% sales tax in €': '% sales tax in €',
		'Other expenses in € incl. <%> VAT.': 'Other expenses in € incl. % VAT.',
		'Other expenses not subject to sales tax': 'Other expenses not subject to sales tax',
		'Please enter an accurate list of VAT-exempt expenses here.':
			'Please enter an accurate list of VAT-exempt expenses here.',
		'Salary-related taxes was updated': 'Salary-related taxes was updated',
		'Total gross salaries (in €)': 'Total gross salaries (in €)',
		'minus 5% social security contribution (in €)': 'minus 5% social security contribution (in €)',
		'= Calculation basis (in €)': '= Calculation basis (in €)',
		'minus 10% wage tax (in €)': 'minus 10% wage tax (in €)',
		'= Net wages (in €)': '= Net wages (in €)',
		'Salary-related taxes (employer`s contribution)': 'Salary-related taxes (employer`s contribution)',
		'of which 6% social security contribution (in €)': 'of which 6% social security contribution (in €)',
		'Statement of revenues and expenses were updated': 'Statement of revenues and expenses were updated',
		'Statement of revenues': 'Statement of revenues',
		'Sales in € incl.': 'Sales in € incl.',
		'% VAT.': '% VAT.',
		'Total gross sales in €': 'Total gross sales in €',
		'Total sales tax': 'Total sales tax',
		'Turnover net total in €': 'Turnover net total in €',
		'Material expenses in € incl.': 'Material expenses in € incl.',
		'Other expenses in € incl.': 'Other expenses in € incl.',
		'Total gross costs in €': 'Total gross costs in €',
		'Input tax total in €': 'Input tax total in €',
		'Total net costs in €': 'Total net costs in €',
		'Advance return of the value added tax (UVA)': 'Advance return of the value added tax (UVA)',
		'Statement of expenses': 'Statement of expenses',
		'List of tasks': 'List of tasks',
		'Calculation half-year result was submitted': 'Calculation half-year result was submitted',
		'The semi-annual report was successfully submitted and cannot be changed now. Date':
			'The semi-annual report was successfully submitted on and cannot be changed now. Date',
		'Statement of income/expenses': 'Statement of income/expenses',
		'Sales net in €': 'Sales net in €',
		'less material and other costs net in €': 'less material and other costs net in €',
		'less personnel costs incl. all taxes in €': 'less personnel costs incl. all taxes in €',
		'less other expenses not subject to sales tax in €': 'less other expenses not subject to sales tax in €',
		'less junior insurance in €': 'less junior insurance in €',
		'Half-year result total in €': 'Half-year result total in €',
		'Calculation half-year expenses': 'Calculation half-year expenses',
		'Sales taxes collected in €': 'Sales taxes collected in €',
		'less input taxes in €': 'less input taxes in €',
		'Wage and salary contributions in €': 'Wage and salary contributions in €',
		'plus junior insurance in €': 'plus junior insurance in €',
		'Submit half-yearly financial statements': 'Submit half-yearly financial statements',
		'Payload or credit': 'Payload or credit',
		'Income/Expense statement ': 'Income/Expense statement ',
		'Calculation of contributions 2nd half': 'Calculation of contributions 2nd half',
		'Financial report second semester': 'Financial report second semester',
		'1st Semiannual Advance return of the value added tax (UVA)':
			'1st Semiannual Advance return of the value added tax (UVA)',
		'2nd Semiannual Advance return of the value added tax (UVA)':
			'2nd Semiannual Advance return of the value added tax (UVA)',
		'= Sales tax calculation': '= Sales tax calculation',
		'Income/Expense statement was updated': 'Income/Expense statement was updated',
		'Income/Expense statement for whole year': 'Income/Expense statement for whole year',
		'Total net income for the year in €': 'Total net income for the year in €',
		'thereof 10% corporate income tax in €': 'thereof 10% corporate income tax in €',
		'Payload or credit was updated': 'Payload or credit was updated',
		'Calculation of contributions 2nd half was submitted': 'Calculation of contributions 2nd half was submitted',
		'Calculated corporate income tax': 'Calculated corporate income tax',
		'Result VAT return in €': 'Result VAT return in €',
		'Total contributions in €': 'Total contributions in €',
		'Submit yearly financial statements': 'Submit yearly financial statements',
		'School deleted successfully': 'School deleted successfully',
		'No business plan yet': 'No business plan yet',
		'No risk analysis yet': 'No risk analysis yet',
		'Go To Students': 'Go To Students',
		'Privacy Statement': 'Privacy Statement',
		'Confirm email': 'Confirm email',
		'Need to confirm email': 'Need to confirm email',
		'Mr.': 'Mr.',
		'Ms.': 'Ms.',
		'Mrs.': 'Mrs.',
		Miss: 'Miss',
		Male: 'Male',
		Female: 'Female',
		Diverse: 'Diverse',
		Products: 'Products',
		Service: 'Service',
		'Services&Products': 'Services&Products',
		'Clothing & Accessories': 'Clothing & Accessories',
		'Office Supplies': 'Office Supplies',
		Electronics: 'Electronics',
		Vehicles: 'Vehicles',
		Vienna: 'Vienna',
		'Lower Austria': 'Lower Austria',
		Burgenland: 'Burgenland',
		Styria: 'Styria',
		'Upper Austria': 'Upper Austria',
		Salzburg: 'Salzburg',
		Tyrol: 'Tyrol',
		Vorarlberg: 'Vorarlberg',
		Carinthia: 'Carinthia',
		'Parental confirmation updated': 'Parental confirmation updated',
		'Managing director': 'Managing director',
		Procurement: 'Procurement',
		Finance: 'Finance',
		'Human Resources / Administration': 'Human Resources / Administration',
		'Marketing / Communication': 'Marketing / Communication',
		'Production / Services': 'Production / Services',
		Other: 'Other',
		'Technology / IT': 'Technology / IT',
		Sales: 'Sales',
		'Avatar successfully updated': 'Avatar successfully updated',
		'Avatar successfully deleted': 'Avatar successfully deleted',
		'Approve expert': 'Approve expert',
		'Disapprove expert': 'Disapprove expert',
		'The expert was rejected': 'The expert was rejected',
		'The expert is approved': 'Тhe expert is approved',
		'Not Activated': 'Not Activated',
		Reviewed: 'Reviewed',
		'Not Reviewed': 'Not Reviewed',
		'Document Type': 'Document Type',
		'Request sent successfully': 'Request sent successfully',
		'View profile': 'View profile',
		'Request to assign': 'Request to assign',
		'Company status': 'Company status',
		'Clear all': 'Clear all',
		'General Profit': 'General Profit',
		'Overdue Reports': 'Overdue Reports',
		'Search reports': 'Search reports...',
		'Clear All': 'Clear All',
		Approved: 'Approved',
		'Get certificate template': 'Get certificate template',
		'The file is too large. Current file size': 'The file is too large. Current file size',
		'Max file size is': 'Max file size is',
		'School Name': 'School Name',
		Number: 'Number',
		'Add Teacher': 'Add Teacher',
		'Risk analysis form successfully updated': 'Risk analysis form successfully updated',
		'Check Confirmed': 'Check Confirmed',
		Reject: 'Reject',
		'Reject Company': 'Reject Company',
		'Reject Parental Confirmation': 'Reject Parental Confirmation',
		'Download template': 'Download template',
		'Approve school': 'Approve school',
		'The school has been approved': 'The school has been approved',
		'Verify teacher': 'Verify teacher',
		'Merge and remove': 'Merge and remove',
		'Teacher status': 'Teacher status',
		Yes: 'Yes',
		No: 'No',
		'Need to upload parental confirmation': 'Need to upload parental confirmation',
		'Need to upload parental confirmation or set Function & working area':
			'Need to upload parental confirmation or set Function & working area',
		'The shareholder was created successfully': 'The shareholder was created successfully',
		'Need review': 'Need review',
		'Analysis & Plan': 'Analysis & Plan',
		'Parental consent': 'Parental consent',
		'Fed. State': 'Fed. State',
		'No main work area yet': 'No main work area yet',
		'School has been created successfully.': 'School has been created successfully.',
		'School has been updated successfully.': 'School has been updated successfully.',
		'Company profile has been updated successfully.': 'Company profile has been updated successfully.',
		'User profile has been updated.': 'User profile has been updated.',
		'Annual deadline': 'Annual deadline',
		'Semiannual deadline': 'Semiannual deadline',
		Deadline: 'Deadline',
		'The letter was sent successfully': 'The letter was sent successfully',
		'No need review': 'No need review',
		'Review status': 'Review status',
		Submitted: 'Submitted',
		Open: 'Open',
		Checked: 'Checked',
		'Company was created successful.': 'Company was created successful.',
		'Wait for an invitation from an existing company': 'Wait for an invitation from an existing company',
		'Company comment is updated': 'Company comment is updated',
		'Wait Invitation': 'Wait Invitation',
		'Not Verified': 'Not Verified',
		'Email successfully changed': 'Email successfully changed',
		'Email change confirmation': 'Email change confirmation',
		Rejected: 'Rejected',
		'Parental confirmation was rejected, upload new please !':
			'Parental confirmation was rejected, upload new please !',
		'Status was updated': 'Status was updated',
		'Invoice created, you can find it in documents': 'Invoice created, you can find it in documents',
		'Change payment': 'Payment received',
		'Change pay': 'Change pay',
		'Company Reminders': 'Company Reminders',
		'1st reminder [x] days after company set-up started and not finished and no login':
			'1st reminder [x] days after company set-up started and not finished and no login',
		'1st reminder [x] days after company profile has been submitted for approval and no login':
			'1st reminder [x] days after company profile has been submitted for approval and no login',
		'Financial Reporting Reminders': 'Financial Reporting Reminders',
		'Junior Company': 'Junior Company',
		'1st reminder [x] days before deadline': '1st reminder [x] days before deadline',
		'Reminder frequency each [x] day(s)': 'Reminder frequency each [x] day(s)',
		'Junior Basic Company and Junior Compact Company': 'Junior Basic Company and Junior Compact Company',
		'Invoicing Reminders': 'Invoicing Reminders',
		'1st reminder [x] days after invoice has been issued': '1st reminderday [x] days after invoice has been issued',
		'Options was updated': 'Options was updated',
		days: 'Days',
		'Teacher Status': 'Teacher Status',
		'Number of Students': 'Number of Students',
		'Number of companies': 'Number of companies',
		details: 'Details',
		'The schools was merged successfully': `The schools was merged successfully`,
		'The teachers was merged successfully': 'The teachers was merged successfully',
		Administrator: 'Administrator',
		'Federal Administrator': 'Federal Administrator',
		'All states': 'All states',
		'Customize Widget': 'Customize Widget',
		VAT: 'VAT',
		'Debit note': 'Debit note',
		'Credit note': 'Credit note',
		Semiannual: 'Semiannual',
		Annual: 'Annual',
		'Open Risk Analysis Form': 'Open Risk Analysis Form',
		Paid: 'Paid',
		'If the invoice amount matches the payment, the invoice status changes to paid. If there is a difference, the balance is not equal to zero.':
			'If the invoice amount matches the payment, the invoice status changes to paid. If there is a difference, the balance is not equal to zero.',
		'Change status': 'Change status',
		'Widget settings updated successfully': 'Widget settings updated successfully',
		'Request failed with status code 401': 'Request failed with status code 401',
		'The new password should differ from the old one': 'The new password should differ from the old one',
		'Request failed with status code 409': 'Request failed with status code 409',
		'To be checked': 'To be checked',
		'Emp.': 'Emp.',
		'Pa.C': 'Pa.C',
		FS: 'FS',
		'Rep.': 'Rep.',
		'Bal.': 'Bal.',
		'Rev.': 'Rev.',
		'Exp.': 'Exp.',
		'Res.': 'Res.',
		'Cha.': 'Cha.',
		'Please wait': 'Please wait',
		'Wrong result': 'Wrong result',
		'Report was successfully submitted.': 'Report was successfully submitted.',
		'You have a credit balance that can be claimed by stating the beneficiary (=account holder), IBAN and this statement as a file attachment via an e-mail to info@junior.cc.':
			'You have a credit balance that can be claimed by stating the beneficiary (=account holder), IBAN and this statement as a file attachment via an e-mail to info@junior.cc.',
		Total: 'Total',
		Active: 'Active',
		Invoice: 'Invoice',
		'Business plan': 'Business plan',
		'Parental confirmation': 'Parental confirmation',
		'Consent document': 'Consent document',
		'Participation document': 'Participation document',
		'Company certificate': 'Company certificate',
		'Shareholder certificate': 'Shareholder certificate',
		ANNUAL: 'ANNUAL',
		SEMIANNUAL: 'SEMIANNUAL',
		'Invoice payment reminder': 'Invoice payment reminder',
		'Assign Request': 'Assign Request',
		'The report has been submitted, the invoice was created. Please pay the invoice.':
			'The report has been submitted, the invoice was created. Please pay the invoice.',
		'Your request was accepted. Company': 'Your request was accepted. Company',
		'Your request was rejected. Company': 'Your request was rejected. Company',
		'wants to be assigned as teacher in your company.': 'wants to be assigned as teacher in your company.',
		'Company Confirmation': 'Company Confirmation',
		'has submitted company for business approval': 'has submitted company for business approval',
		'Congratulation!': 'Congratulation!',
		'Great Job, you have been awarded for participation in Junior Company Program - download your certificate':
			'Great Job, you have been awarded for participation in Junior Company Program - download your certificate',
		Payment: 'Payment',
		'You company has paid the invoice': 'You company has paid the invoice',
		'You company has to make a payment invoice': 'You company has to make a payment invoice',
		'New User': 'New User',
		'joined successfully to company !': 'joined successfully to company !',
		'Congratulation, you have successfully submitted the': 'Congratulation, you have successfully submitted the',
		'Fin. Report': 'Fin. Report',
		'Teacher Confirmation': 'Teacher Confirmation',
		'Congratulation, your account was confirmed by an admin':
			'Congratulation, your account was confirmed by an admin',
		'Congratulation, your parental confirmation has been approved by an admin':
			'Congratulation, your parental confirmation has been approved by an admin',
		'Congratulation, your company has been approved by an admin':
			'Congratulation, your company has been approved by an admin',
		'Report was rejected': 'Report was rejected',
		'Company report was rejected. Please check and submit report again.':
			'Company report was rejected. Please check and submit report again.',
		Employees: 'Employees',
		'Rep.Typ': 'Rep.Typ',
		Revenues: 'Revenues',
		Expanses: 'Expanses',
		Charges: 'Charges',
		Reported: 'Reported',
		'Deadline (d)': 'Deadline (d)',
		'Invoice Number': 'Invoice Number',
		Credit: 'Credit',
		'View Report': 'View Report',
		'Reject report': 'Reject report',
		'Not approved': 'Not approved',
		'Company Rejected': 'Company Rejected',
		'Submit Report': 'Submit Report',
		'Please submit report before deadline !': 'Please submit report before deadline !',
		'Edit Userprofile': 'Edit Userprofile',
		'Download parental consent': 'Download parental consent',
		'Download parental consent (not uploaded yet!)': 'Download parental consent (not uploaded yet!)',
		'Parental consent verfied': 'Parental consent verfied',
		'Parental consent (need verifying)': 'Parental consent (need verifying)',
		'New Employee': 'New Employee',
		'to work in the company.': 'to work in the company.',
		'New Teacher': 'New Teacher',
		'has invited the teacher': 'has invited the teacher',
		'to the enterprise.': 'to the enterprise.',
		'New Expert': 'New Expert',
		'as a economic experts.': 'as a economic experts.',
		'has registered': 'has registered',
		'Expert Confirmation': 'Expert Confirmation',
		'Great, you have dealt with the market and risk analysis.':
			'Great, you have dealt with the market and risk analysis.',
		'Your business plan has been successfully uploaded.': 'Your business plan has been successfully uploaded.',
		'has uploaded the parent/guardian consent form.': 'has uploaded the parent/guardian consent form.',
		'has submitted the company for verification and activation by JA Austria.':
			'has submitted the company for verification and activation by JA Austria.',
		'The parental/guardian consent form of': 'The parental/guardian consent form of',
		'has been verified and confirmed.': 'has been verified and confirmed.',
		'The declaration of consent of the parents or legal guardians of':
			'The declaration of consent of the parents or legal guardians of',
		'was rejected.': 'was rejected.',
		'Congratulations! Your company has been successfully verified by JA Austria. For the legitimation of your business, we have issued a company register extract':
			'Congratulations! Your company has been successfully verified by JA Austria. For the legitimation of your business, we have issued a company register extract',
		'must be uploaded up to a max. amount of': 'must be uploaded up to a max. amount of',
		'for you. For the sale of your company shares, the signed warrants':
			'for you. For the sale of your company shares, the signed warrants',
		'in the Share section.': 'in the Share section.',
		'Do not forget to prepare your financial report by': 'Do not forget to prepare your financial report by',
		'Your company has been reviewed by JA Austria and unfortunately could not be approved for business yet.':
			'Your company has been reviewed by JA Austria and unfortunately could not be approved for business yet.',
		'Congratulations, your financial report has arrived at JA Austria! On the basis of your data, a compilation of the levies has been prepared, which you can find in the "Documents" section. An open invoice':
			'Congratulations, your financial report has arrived at JA Austria! On the basis of your data, a compilation of the levies has been prepared, which you can find in the "Documents" section. An open invoice',
		'must be paid by bank transfer within': 'must be paid by bank transfer within',
		'days.': 'days.',
		'Attention. Your financial report has been put on hold after submission and needs to be resubmitted after revision.':
			'Attention. Your financial report has been put on hold after submission and needs to be resubmitted after revision.',
		Congratulations: 'Congratulations',
		'You have successfully completed the JA Austria programme. You can find your certificate in the "Documents" section':
			'You have successfully completed the JA Austria programme. Sie finden Ihr Teilnahmezertifikat im Bereich "Dokumente"',
		'Consent of parent or guardian': 'Consent of parent or guardian',
		'is a required field': 'is a required field',
		'is must be less than 10000 characters': 'is must be less than 10000 characters',
		'is must be greater than 200 characters': 'is must be greater than 200 characters',
		'Total fin. result': 'Total fin. result',
		'Wage and Salary contributions': 'Wage and Salary contributions',
		'Salary-dependent contributions (employee share)': 'Salary-dependent contributions (employee share)',
		'Salary-related contributions (employer`s contribution)':
			'Salary-related contributions (employer`s contribution)',
		'Show Reports': 'Show Reports',
		'Repeat password does not match with Password': 'repeat password does not match with Password',
		'Option warrant': 'Option warrant',
		'Certificate of completion': 'Certificate of completion',
		'Welcome !': 'Welcome !',
		'Here you will find information about upcoming tasks around your company as well as hints about interesting offers and events of our partners.':
			'Here you will find information about upcoming tasks around your company as well as hints about interesting offers and events of our partners.',
		'Before your company starts business, it must be submitted to JA Austria for verification and activation. After activation you can offer your products, services and shares in your company on the market - for the legitimisation of these activities you will receive a company register extract and the template for issuing warrants.':
			'Before your company starts business, it must be submitted to JA Austria for verification and activation. After activation you can offer your products, services and shares in your company on the market - for the legitimisation of these activities you will receive a company register extract and the template for issuing warrants.',
		'The financial report provides you with information on the necessary taxes that are usually incurred in the course of business activities.':
			'The financial report provides you with information on the necessary taxes that are usually incurred in the course of business activities.',
		'Good luck for your company': 'Good luck for your company',
		Request: 'Request',
		'Open a modal window and confirm or reject the request.':
			'Open a modal window and confirm or reject the request.',
		'Further support teachers': 'Further support teachers',
		'Final Report uploaded successfully': 'Final Report uploaded successfully',
		'Business Plan uploaded successfully': 'Business Plan uploaded successfully',
		'Business Plan removed successfully': 'Business Plan removed successfully',
		'Please log out and wait until you are invited by a member of an existing company. After being invited and logging in again, you will be immediately assigned to your joint company as an employee.':
			'Please log out and wait until you are invited by a member of an existing company. After being invited and logging in again, you will be immediately assigned to your joint company as an employee.',
		"Don't forget to submit your company for approval (button in dashboard area)":
			"Don't forget to submit your company for approval (button in dashboard area)",
		'days!': 'days!',
		'Company activation page.': 'Company activation page.',
		Reminder: 'Reminder',
		'Business Report': 'Business Report',
		'Your business report has been successfully uploaded.': 'Your business report has been successfully uploaded.',
		'Download Final Report': 'Download Final Report',
		'Download Business Plan': 'Download Business Plan',
		'Shareholder Share for Junior': 'Shareholder Share for Junior',
		'Shareholder Share for Junior Compact': 'Shareholder Share for Junior Compact',
		'Shareholder Share for Junior Basic': 'Shareholder Share for Junior Basic',
		'Junior Annual report deadline': 'Junior Annual report deadline',
		'Junior Semiannual report deadline': 'Junior Semiannual report deadline',
		'Junior Compact report deadline': 'Junior Compact report deadline',
		'Junior Basic report deadline': 'Junior Basic report deadline',
		'Anonimize and clear db': 'Anonimize and clear db',
		'The open invoice from for was paid incompletely. Your company account has a balance of. Please transfer the difference to the account details provided.':
			'The open invoice from {{val1}} for {{val2}} was paid incompletely. Your company account has a balance of {{val3}}. Please transfer the difference to the account details provided.',
		'New employee invited': 'New employee invited',
		'has invited to work in the company.': '{{val1}} has invited {{val2}} to work in the company.',
		'has invited the teacher to the enterprise.': '{{val1}} has invited {{val2}} the teacher to the enterprise.',
		'Teacher invited': 'Teacher invited',
		'has registered as a company expert.': '{{val1}} has registered {{val2}} as a company expert.',
		'Economic expert added': 'Economic expert added',
		'Business expert agrees with the GDPR.': 'Business expert {{val1}} agrees with the GDPR.',
		'Economic expert confirmed': 'Economic expert confirmed',
		'Market- & Riskanalysis': 'Market- & Riskanalysis',
		'Parent/guardian consent': 'Parent/guardian consent',
		'Company under verfication': 'Company under verfication',
		'The parental/guardian consent form of has been verified and confirmed.':
			'The parental/guardian consent form of {{val1}} has been verified and confirmed.',
		'Consent confirmed': 'Consent confirmed',
		'Consent rejected': 'Consent rejected',
		'The declaration of consent of the parents or legal guardians of was rejected.':
			'The declaration of consent of the parents or legal guardians of {{val1}} was rejected.',
		'Company verified': 'Company verified',
		'Company not approved': 'Company not approved',
		'Financial report reminder': 'Financial report reminder',
		'Financial report rejected': 'Financial report rejected',
		'The open invoice from for has been paid.': 'The open invoice from {{val1}} for {{val2}} has been paid.',
		'Participation certificate': 'Participation certificate',
		'Invoice amount paid incompletely': 'Invoice amount paid incompletely',
		'Invoice generated': 'Invoice generated',
		'Here you will find information about activities of the companies you support as well as information about interesting offers and events of our partners.':
			'Here you will find information about activities of the companies you support as well as information about interesting offers and events of our partners.',
		'The JA Austria company has added you as a supervising teacher. You can now follow the details of the assigned companies in the "Companies" section and also have access to their documents such as business plan, shareholder certificates, financial reports and annual report.':
			'The JA Austria company {{val1}} has added you as a supervising teacher. You can now follow the details of the assigned companies in the "Companies" section and also have access to their documents such as business plan, shareholder certificates, financial reports and annual report.',
		'New company': 'New company',
		'Report submitted': 'Report submitted',
		'The company you supervise has filed. You can find the report here':
			'The company you supervise {{val1}} has {{val2}} filed. You can find the report here',
		'Congratulations! Your company has submitted its financial report and thus successfully completed the JA Austria Programme. For the students involved in the company we have deposited completion certificates in the "Documents" section - the students can download them independently. You can find the business report here ':
			'Congratulations! Your company {{val1}} has submitted its financial report and thus successfully completed the JA Austria Programme. For the students involved in the company we have deposited completion certificates in the "Documents" section - the students can download them independently. You can find the business report here ',
		'We would also like to thank you very much for your support of the JA Austria programme with a certificate':
			'We would also like to thank you very much for your support of the JA Austria programme with a certificate',
		'Financial report submitted & certificates have been issued':
			'Financial report submitted & certificates have been issued',
		'Attention the following JA Austria companies you are in charge of have not yet submitted their report in our system and therefore cannot successfully complete the programme. Company: ':
			'Attention the following JA Austria companies you are in charge of have not yet submitted their report in our system and therefore cannot successfully complete the programme. Company: ',
		'Congratulations! The company you are in charge of has been checked by JA Austria and activated for further business activities. The students have received an extract from the company register and can now sell shares in the company up to a total value of EUR . The following date(s) have been set for report submission':
			'Congratulations! The company you are in charge of {{val1}} has been checked by JA Austria and activated for further business activities. The students have received an extract from the company register and can now sell shares in the company up to a total value of EUR {{val2}} . The following date(s) have been set for report submission',
		'Invoice paid': 'Invoice paid',
		Branches: 'Branches',
		Planned: 'Planned',
		'Delete employee': 'Delete employee',
		'to your company in the company profile section.': 'to your company in the company profile section.',
		'Ready for submission': 'Ready for submission',
		'Congratulations you have everything complete. Your company can now be submitted to JA Austria for review.':
			'Congratulations you have everything complete. Your company can now be submitted to JA Austria for review.',
		'Final Report': 'Final Report',
		'Please upload your final report via the upload function in the dashboard. Afterwards you will find your participation certificates for the successful completion of the JA Austria programme in the document section.':
			'Please upload your final report via the upload function in the dashboard. Afterwards you will find your participation certificates for the successful completion of the JA Austria programme in the document section.',
		'Open profile': 'Open profile',
		'You have new news - click to look details': 'You have new news - click to look details',
		and: 'and',
		others: 'others',
		'This field must be over 0': 'This field must be over 0',
		Delete: 'Delete',
		'Delete the company': 'Delete the company',
		'The company successfully deleted': 'The company successfully deleted',
		'Upload logo & product images': 'Upload logo & product images',
		'Function & working area': 'Function & working area',
		'The company must not be leaderless!': 'The company must not be leaderless!',
		'First assign your CEO function to another person!': 'First assign your CEO function to another person!',
		'CEO / Managing Director': 'CEO / Managing Director',
		'Add link': 'Add link',
		'CEO role can be transferred in the view "Employees" via the 3-point menu':
			'CEO role can be transferred in the view "Employees" via the 3-point menu',
		'Edit function & working area': 'Edit function & working area',
		public: 'public',
		private: 'private',
		Explanation: 'Explanation',
		'Not verified': 'Not verified',
		Show: 'Show',
		'Wait for confirmation new employee': 'Wait for confirmation new employee',
		'Change view': 'Change view',
		years: 'years',
		'A working area has to be selected. Please assign for a working area':
			'A working area has to be selected. Please assign for a working area',
		'This icon shows incomplete steps, such as missing parental confirmation, unassigned working area, or pending employee invitation.':
			'This icon shows incomplete steps, such as missing parental confirmation, unassigned working area, or pending employee invitation.',
		'Gross salaries may not be EUR 0 in both semesters': 'Gross salaries may not be EUR 0 in both semesters',
		'Remove employee.': 'Remove employee.',
		'No working area': 'No working area',
		'The company must consist of at least two employees': 'The company must consist of at least two employees',
		"You are currently in a student's view": " You are currently in a student's view",
		'Website & Social Media': 'Website & Social Media',
		'Please note the URL syntax e.g. https://www.junior.cc':
			'Please note the URL syntax e.g. https://www.junior.cc',
		'The invitation to join the company has been revoked. Begin your registration in the system without the invitation link to create your own company.':
			'The invitation to join the company has been revoked. Begin your registration in the system without the invitation link to create your own company.',
		Export: 'Export',
		'Download report': 'Download report',
		'Total charges in €': 'Total charges in €',
		'We have created an': 'We have created an',
		'based on your report. Please pay it within the next': 'based on your report. Please pay it within the next',
		'You can find the invoice in the': 'You can find the invoice in the',
		'Please do not forget to submit your financial report.':
			'Please do not forget to submit your financial report.',
		'Half-year report': 'Half-year report',
		'annual report': 'annual report',
		'Create message': 'Create message',
		Content: 'Content',
		Stateadmin: 'State admin',
		Federaladmin: 'Federal admin',
		ADMINISTRATOR: 'State admin',
		FEDERAL_ADMINISTRATOR: 'Federal admin'
	}
}

export default en
