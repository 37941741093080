import Joi from 'joi'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Checkbox from '../../../../UI/Checkbox'
import Btn from '../../../../UI/Btn'
import TextField from '../../../../UI/TextField'
import SelectField from '../../../../UI/SelectField'
import BaseModal from '../../../../UI/BaseModal/BaseModal'
import { schemaOptions } from '../../../../shared/i18n'
import http from '../../../../shared/http'
import { types } from '../../../../shared/eventEmitter'
import { TApi, StateOptions, genderOption, TLanguages, TRoles } from '../../../../shared/const'

import styles from './style.module.scss'

const schema = Joi.object({
	firstName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^([a-zA-ZА-яЁёÄÖÜẞäöüß])/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	lastName: Joi.string()
		.min(2)
		.max(26)
		.regex(/^([a-zA-ZА-яЁёÄÖÜẞäöüß])/)
		.required()
		.messages({
			'string.pattern.base': 'Only letters are allowed !'
		}),
	email: Joi.string().email({ tlds: false }).required(),
	gender: Joi.string().required(),
	salutation: Joi.string().required(),
	password: Joi.string().allow(''),
	role: Joi.string().valid(TRoles.ADMIN, TRoles.FEDERAL_ADMIN).required().messages({
		'any.only': 'Invalid role selected!'
	}),
	state: Joi.string().when('role', {
		is: TRoles.FEDERAL_ADMIN,
		then: Joi.valid(null).allow(''), // allow null or empty string for federal admin
		otherwise: Joi.required() // require state for non-federal admin
	}),
	organizationName: Joi.string().when('role', {
		is: TRoles.FEDERAL_ADMIN,
		then: Joi.valid(null).allow(''), // allow null or empty string for federal admin
		otherwise: Joi.required() // require organization name for non-federal admin
	}),
	manageStateAdmins: Joi.boolean().default(false),
	manageDatabaseReset: Joi.boolean().default(false),
	manageFederalAdmins: Joi.boolean().default(false)
})

function AddAdmin(props) {
	const permissions = props.permissions

	const { t, i18n } = useTranslation()
	const {
		reset,
		control,
		setValue,
		trigger,
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(schema, {
			errors: {
				labels: false,
				language: localStorage.getItem('i18nextLng')
			},
			...schemaOptions
		})
	})

	const [id, setId] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const [onDone, setOnDone] = useState(null)
	const [isManageStateAdminsChecked, setManageStateAdminsChecked] = useState(false)
	const [isManageFederalAdminsChecked, setManageFederalAdminsChecked] = useState(false)
	const [isManageDatabaseResetChecked, setManageDatabaseResetChecked] = useState(false)

	const selectedRole = watch('role')

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	const onSubmit = (form, closeModal) => {
		setLoading(true)

		const finalForm = {
			...form,
			...(form.role === TRoles.FEDERAL_ADMIN && { state: null })
		}

		const request = id
			? http.put(TApi.ADMIN_STATE_ADMINS_ID(id), finalForm)
			: http.post(TApi.ADMIN_STATE_ADMINS, {
					...finalForm,
					email: form.email.toLowerCase()
			  })

		request
			.then(() => {
				toast.success(`${t('Admin was successfully')} ${id ? t('updated') : t('created')} `)
				setId(null)
				closeModal()
				if (onDone) onDone()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleOpen = (admin = null, onDone = null) => {
		setOnDone(() => onDone)

		if (!admin) return
		setId(admin.id || null)
		setValue('firstName', admin.firstName || '')
		setValue('lastName', admin.lastName || '')
		setValue('email', admin.email || '')
		setValue('state', admin.state || '')
		setValue('organizationName', admin.organizationName || '')
		setValue('salutation', admin.salutation || '')
		setValue('gender', admin.gender || '')
		setValue('role', admin.role || '')
		setValue('manageStateAdmins', admin?.permissions?.manageStateAdmins || false)
		setValue('manageDatabaseReset', admin?.permissions?.manageDatabaseReset || false)
		setValue('manageFederalAdmins', admin?.permissions?.manageFederalAdmins || false)
	}

	const handleClose = () => {
		reset()
		setId(null)
	}

	const rolesOptions = [TRoles.ADMIN, TRoles.FEDERAL_ADMIN].map(item => ({
		label: item,
		value: item
	}))

	const handleManageStateAdminsChange = event => {
		setManageStateAdminsChecked(event.target.checked)
	}

	const handleManageFederalAdminsChange = event => {
		setManageFederalAdminsChecked(event.target.checked)
	}

	const handleManageDatabaseResetChange = event => {
		setManageDatabaseResetChecked(event.target.checked)
	}

	return (
		<BaseModal
			onClose={handleClose}
			onOpen={handleOpen}
			nameEvent={types.openAddAdmin}
			title={t('Add Administrator')}
			isLoading={isLoading}
			maxWidth={522}>
			{({ closeModal }) => (
				<form
					className={styles.root}
					onSubmit={handleSubmit(form =>
						onSubmit(form, () => {
							closeModal()
							setId(null)
						})
					)}>
					{permissions?.manageFederalAdmins && (
						<SelectField
							label={t('Admin roles')}
							className={styles.textField}
							control={control}
							error={errors.roles}
							valuesToOptions
							mb={25}
							options={rolesOptions.map(v => ({
								label: t(v.value),
								value: v.value
							}))}
							name="role"
							placeholder={t('Select admin role')}
							required={true}
						/>
					)}

					{selectedRole === TRoles.FEDERAL_ADMIN && (
						<div className={styles.permissionsSection}>
							<Checkbox
								label={t('Manage State Admins')}
								checked={isManageStateAdminsChecked}
								register={register('manageStateAdmins')}
								onChange={handleManageStateAdminsChange}
							/>
							<Checkbox
								label={t('Manage Federal Admins')}
								checked={isManageFederalAdminsChecked}
								register={register('manageFederalAdmins')}
								onChange={handleManageFederalAdminsChange}
							/>
							<Checkbox
								label={t('Manage Database Reset')}
								checked={isManageDatabaseResetChecked}
								register={register('manageDatabaseReset')}
								onChange={handleManageDatabaseResetChange}
							/>
						</div>
					)}
					<SelectField
						className={styles.textField}
						mb={25}
						required
						label={t('salutation')}
						error={errors.salutation}
						placeholder={t('Select salutation')}
						options={props.salutations?.map(v => ({
							value: v.value_EN,
							label: i18n.language === TLanguages.EN ? v.value_EN : v.value_DE
						}))}
						control={control}
						name="salutation"
					/>
					<SelectField
						className={styles.textField}
						mb={25}
						required
						label={t('gender')}
						error={errors.gender}
						placeholder={t('Select gender')}
						options={genderOption
							.map(v => ({ label: t(v.label), value: v.value }))
							.sort((a, b) => a.label.localeCompare(b.label))}
						control={control}
						name="gender"
					/>
					<TextField
						className={styles.textField}
						mb={25}
						required
						label={t('First Name')}
						register={register('firstName')}
						error={errors.firstName}
						placeholder={t('Type First Name')}
					/>
					<TextField
						className={styles.textField}
						mb={25}
						required
						label={t('Last Name')}
						register={register('lastName')}
						error={errors.lastName}
						placeholder={t('Type Last Name')}
					/>
					<TextField
						className={styles.textField}
						disabled={id}
						required
						mb={25}
						label="Email"
						register={register('email')}
						error={errors.email}
						placeholder="email"
					/>

					<TextField
						className={styles.textField}
						mb={25}
						required={selectedRole === TRoles.ADMIN}
						label={t('Organization name')}
						register={register('organizationName')}
						error={errors.organizationName}
						placeholder={t('Organization name')}
					/>
					<SelectField
						className={styles.textField}
						mb={25}
						required={selectedRole === TRoles.ADMIN}
						label={t('state')}
						error={errors.state}
						placeholder={t('state')}
						options={StateOptions.map(v => ({
							label: t(v.label),
							value: v.value
						})).sort((a, b) => a.label.localeCompare(b.label))}
						control={control}
						name="state"
					/>

					{id && (
						<TextField
							className={styles.textField}
							mb={25}
							type="password"
							label={t('New password')}
							placeholder={t('New password')}
							error={errors.password}
							register={register('password')}
						/>
					)}

					<Btn
						loading={isLoading}
						width={155}
						className={styles.btnSubmit}
						type="submit"
						icon="send"
						iconSize={{ w: 15, h: 15 }}
						iconColor="#fff">
						{t('save')}
					</Btn>
				</form>
			)}
		</BaseModal>
	)
}
AddAdmin.propTypes = {
	salutations: PropTypes.array,
	genders: PropTypes.array
}
const mapStateToProps = state => ({
	salutations: state.options.salutations,
	genders: state.options.genders,
	permissions: state.user.profile.permissions
})
export default connect(mapStateToProps)(AddAdmin)
