import { useEffect, useMemo, useState } from 'react'
import Table from '../../../../UI/Table'
import MiniHeader from '../../../../UI/MiniHeader'
import { useTranslation } from 'react-i18next'

import styles from './style.module.scss'
import { StateOptions, TApi, TRoles } from '../../../../shared/const'
import Btn from '../../../../UI/Btn'
import eventEmitter, { types } from '../../../../shared/eventEmitter'
import VisibilityAccess from '../../../VisibilityAccess'
import http from '../../../../shared/http'
import BtnTooltip from '../../../../UI/BtnTooltip'
import Pagination from '../../../../UI/Pagination'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

function Admins(props) {
	const permissions = props.permissions

	const { t } = useTranslation()
	const [admins, setAdmins] = useState([])
	const [countAdmins, setCountAdmins] = useState(0)
	const [page, setPage] = useState(0)
	const [sort, setSort] = useState({ name: 'state', direction: 'asc' })
	const limit = 10

	const arePermissionsLoaded = Boolean(permissions)

	const fetchAdmins = offset => {
		http.get(TApi.ADMIN_STATE_ADMINS, {
			params: {
				limit,
				offset,
				direction: sort.direction,
				order: sort.name
			}
		}).then(({ data }) => {
			setAdmins(data.rows)
			setCountAdmins(data.count)
		})
	}

	useEffect(() => {
		if (arePermissionsLoaded) {
			fetchAdmins(page * limit)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, sort, arePermissionsLoaded])

	const handleDeleteAdminWithConfirmation = async id => {
		const { isConfirmed } = await Swal.fire({
			title: t('Are you sure?'),
			text: t('You will not be able to revert this!'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0CA789',
			cancelButtonColor: '#d33',
			confirmButtonText: t('Yes, delete it!'),
			cancelButtonText: t('Cancel')
		})

		if (!isConfirmed) return

		toast.info(t('Deleting admin...'))
		try {
			await http.delete(TApi.ADMIN_STATE_ADMINS_ID(id))
			toast.success(t('Admin was successfully deleted'))
			fetchAdmins(page * limit)
		} catch (error) {
			toast.error(t('Failed to delete admin. Please try again later.'))
		}
	}

	const handleSort = (name, direction) => {
		setSort({ name, direction })
	}

	const handleDone = () => fetchAdmins(page * limit)

	const columns = useMemo(
		() =>
			arePermissionsLoaded
				? [
						{
							Header: t('Role'),
							accessor: 'role',
							minWidth: 150,
							Cell: ({ row }) => {
								const role = row.original.role

								const roleName =
									role === TRoles.ADMIN
										? t('Stateadmin')
										: role === TRoles.FEDERAL_ADMIN
										? t('Federaladmin')
										: role

								return <p style={{ minWidth: '200px' }}>{roleName}</p>
							}
						},

						{
							Header: 'Name',
							accessor: 'firstName',
							minWidth: 200,
							Cell: ({ row }) => {
								const { role: rowRole } = row.original

								const canManageStateAdmin = rowRole === TRoles.ADMIN && permissions?.manageStateAdmins

								const canManageFederalAdmin =
									rowRole === TRoles.FEDERAL_ADMIN && permissions?.manageFederalAdmins

								const shouldShowButtons = canManageStateAdmin || canManageFederalAdmin

								return (
									<div className={styles.name}>
										<p className={styles.state}>
											{row.original.firstName} {row.original.lastName}
										</p>
										{shouldShowButtons && (
											<BtnTooltip
												className={styles.btnToolTip}
												items={[
													{
														label: t('update'),
														onClick: () =>
															eventEmitter.emit(
																types.openAddAdmin,
																row.original,
																handleDone
															)
													},
													{
														className: styles.btnRemove,
														label: t('Remove'),
														onClick: () => {
															handleDeleteAdminWithConfirmation(row.original.id)
														}
													}
												]}
											/>
										)}
									</div>
								)
							}
						},

						{
							Header: t('state'),
							accessor: 'state',
							Cell: ({ row }) => {
								return (
									<div className={styles.name}>
										<p className={styles.state} style={{ minWidth: '250px' }}>
											{row.original.state
												? t(
														StateOptions.find(({ value }) => value === row.original.state)
															?.label
												  )
												: t('All')}
										</p>
									</div>
								)
							}
						},
						{
							Header: t('Organization name'),
							accessor: 'organizationName',
							minWidth: 250,
							Cell: ({ row }) => {
								return <p style={{ minWidth: '200px' }}>{row.original.organizationName}</p>
							}
						},
						{
							Header: t('Administrator email'),
							accessor: 'email',
							minWidth: 250,
							Cell: ({ row }) => {
								return (
									<div className={styles.email}>
										<span>{row.original.email}</span>
									</div>
								)
							}
						}
				  ]
				: [],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[permissions, t, arePermissionsLoaded]
	)
	return (
		<div className={styles.root}>
			<MiniHeader className={styles.miniHeader} title={t('stateAdmins')}>
				<VisibilityAccess access={[TRoles.ADMIN, TRoles.FEDERAL_ADMIN]}>
					<Btn
						width={168}
						onClick={() => eventEmitter.emit(types.openAddAdmin, null, handleDone)}
						iconSize={{ w: 10, h: 10 }}
						icon="arrow"
						iconColor="white"
						theme="orange">
						{t('Add Administrator')}
					</Btn>
				</VisibilityAccess>
			</MiniHeader>
			{arePermissionsLoaded ? (
				<>
					<div className={styles.subHeader}>
						<Pagination
							limit={limit}
							page={page}
							label={t('Administrators')}
							countAll={countAdmins}
							onChange={setPage}
						/>
					</div>
					<div className="container">
						<Table
							items={admins}
							page={page}
							paginationLabel={t('Administrators')}
							columns={columns}
							handleSort={handleSort}
							onChange={setPage}
							countAll={countAdmins}
						/>
					</div>
				</>
			) : (
				<p>{t('Loading permissions...')}</p>
			)}
		</div>
	)
}

const mapStateToProps = state => ({
	permissions: state.user.profile.permissions
})

export default connect(mapStateToProps)(Admins)
